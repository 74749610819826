import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Head from 'components/head';
import Logo from 'components/logo';
import Title from 'components/title';
import MarkdownWrapper from 'components/markdownwrapper';

const centerItems = {
  textAlign: 'center',
};

const amenitiesText = {
  textAlign: 'left',
  zIndex: '2',
};

const zindex = {
  zIndex: '2',
};

const Amenities = ({ data }) => (
  <Layout>
    <Head pageTitle={data.amenitiesJson.title} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div style={centerItems}>
            <Logo />
            <Title as="h1" size="large">
              {data.amenitiesJson.title}
            </Title>
          </div>
        </Col>
      </Row>
      <div style={{ height: '5vh' }} />
      <Col col={12} sm={12} style={zindex}>
        <Row>
          <div style={amenitiesText}>
            <MarkdownWrapper>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.amenitiesJson.content.childMarkdownRemark.html,
                }}
              />
            </MarkdownWrapper>
          </div>
        </Row>
      </Col>
    </Container>
  </Layout>
);

Amenities.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Amenities;

export const query = graphql`
  query AmenitiesQuery {
    amenitiesJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
